import {
  bannerPositionsInFeeds,
  bannerTargetsInFeeds,
  heightForBannerPositionInFeeds,
  bannerItemPositionsInFeeds,
  minViewPortSizeForBannersInFeeds,
  displayBannerPositions,
  refreshBannerPositions,
} from '../constants/banners';

const showBannersInFeeds = () => {
  const currentViewPortSize = window.innerWidth;
  return currentViewPortSize >= minViewPortSizeForBannersInFeeds;
};

const bannerItemPositionInFeeds = (isMpCardDoubleTileEnabled: boolean) => {
  if (isMpCardDoubleTileEnabled) {
    return bannerItemPositionsInFeeds.WITH_DOUBLE_TILE_MP_CARD;
  }

  const positionLongScreen = bannerItemPositionsInFeeds.IN_LONG_SCREEN;
  const positionShortScreen = bannerItemPositionsInFeeds.IN_SHORT_SCREEN;
  return window.innerHeight >= heightForBannerPositionInFeeds ? positionLongScreen : positionShortScreen;
};

// will be triggered on every new feed request
const refreshBannerPosition = (activeFeedId) => {
  if (window.ecgJsBanners?.refreshBids && refreshBannerPositions.indexOf(activeFeedId) > -1 && showBannersInFeeds()) {
    window.ecgJsBanners?.refreshBids(bannerTargetsInFeeds[activeFeedId]);
  }
};

let queuedPosition;

const displayQueuedPosition = () => {
  if (queuedPosition) {
    window.ecgJsBanners?.setupAdditionalBanners([queuedPosition]);
  }
};

// will be triggered only when the first feed items are ready
const displayBannerInFeed = (activeFeedId) => {
  // check if feed id exists
  if (displayBannerPositions.indexOf(activeFeedId) > -1) {
    // check if ecg-banner-lib is ready
    if (window.ecgJsBanners?.setupAdditionalBanners) {
      // check if initial load or tab change
      if (bannerPositionsInFeeds[activeFeedId]?.isCalled) {
        refreshBannerPosition(activeFeedId);
      } else {
        window.ecgJsBanners?.setupAdditionalBanners([bannerPositionsInFeeds[activeFeedId].key]);
        bannerPositionsInFeeds[activeFeedId].isCalled = true;
      }
    } else {
      queuedPosition = bannerPositionsInFeeds[activeFeedId].key;
    }
  }
};

const showBannersOnMobile = () => {
  // check if mobile position active
  if (!showBannersInFeeds()) {
    // check if ecg-banner-lib is ready
    if (window.ecgJsBanners?.setupAdditionalBanners) {
      window.ecgJsBanners?.setupAdditionalBanners([bannerPositionsInFeeds.MOBILE.key]);
    } else {
      queuedPosition = bannerPositionsInFeeds.MOBILE.key;
    }
  }
};

export {
  bannerItemPositionInFeeds,
  refreshBannerPosition,
  displayBannerInFeed,
  showBannersInFeeds,
  showBannersOnMobile,
  displayQueuedPosition,
};
