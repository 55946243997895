import React, { useState } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { VerticalListing, Link, MaskedLink } from '@hz-design-system/web-ui';
import { formatImageUrl, ImageSize } from '@ecg-marktplaats/media-util-js-lib';

import formatPrice from './utils/formatPrice';
import { TFeedItem } from 'types/TFeeds';

type TFeedItemProps = {
  item: TFeedItem;
  lazyLoadImage: boolean;
  onClick: Function;
};

const FeedItem = ({ item, lazyLoadImage, onClick }: TFeedItemProps) => {
  const { t } = useI18nContext();

  const [url, setUrl] = useState(item.url);

  // eslint-disable-next-line no-undefined
  const imageLoading = lazyLoadImage ? 'lazy' : undefined;
  const imageDecoding = lazyLoadImage ? 'async' : undefined;

  const LinkComponent = item.thinContent ? MaskedLink : Link;

  return (
    <LinkComponent
      kind="block"
      href={url}
      onClick={() => onClick(item.correlationId)}
      onContextMenu={() => {
        const newUrl = `${item.url}${item.url.includes('?') ? '&' : '?'}correlationId=${item.correlationId}`;
        setUrl(newUrl);
      }}
    >
      <VerticalListing
        title={item.title}
        price={formatPrice(item.price, t)}
        imageSrc={formatImageUrl(item.picture?.url, ImageSize.Large, item.picture?.sizes)}
        imageLoading={imageLoading}
        decoding={imageDecoding}
        imageOverlayLabel={item.isHomepageAdLabel ? t('homepage.feeds.item.traits.homepage_ad') : ''}
      />
    </LinkComponent>
  );
};

export default React.memo(FeedItem);
