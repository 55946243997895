import React, { useEffect, useState } from 'react';

import { EnvironmentProvider } from '../../../contexts/EnvironmentContext';

type TProps = {
  environment: any;
};

let SmartBannerFullScreen;

const SmartBannerFullScreenApp = ({ environment }: TProps) => {
  const [isSmartBannerModalLoaded, setIsSmartBannerModalLoaded] = useState(false);

  useEffect(() => {
    const loadComponent = async () => {
      try {
        SmartBannerFullScreen = (
          await import(/* webpackChunkName: "SmartBannerFullScreen" */ './components/SmartBannerFullScreen')
        ).default;
        setIsSmartBannerModalLoaded(true);
      } catch (err) {}
    };

    loadComponent();
  }, []);

  if (!isSmartBannerModalLoaded) {
    return null;
  }

  return (
    <EnvironmentProvider value={environment}>
      <SmartBannerFullScreen />
    </EnvironmentProvider>
  );
};

export default SmartBannerFullScreenApp;
