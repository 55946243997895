import React from 'react';
import { CardCollection } from '@hz-design-system/web-ui';
import BlockSkeleton from '../Skeleton/BlockSkeleton';
import TextSkeleton from '../Skeleton/TextSkeleton';
import Classes from '../Skeleton/Skeleton.scss';

import FEED_ITEMS_PER_PAGE from '../../constants/feedItemsPerPage';

type TFeedItemListSkeleton = {
  itemsCount?: number;
};

const FeedItemListSkeleton = ({ itemsCount = FEED_ITEMS_PER_PAGE }: TFeedItemListSkeleton) => (
  <CardCollection kind="grid">
    {Array(itemsCount)
      .fill(true)
      .map((_, index) => (
        <div className={`hz-Card ${Classes.noShadow}`} key={index}>
          <article className={`hz-Listing hz-Listing--vertical ${Classes.noShadow}`}>
            <div className={`hz-Image hz-Listing-image ${Classes.border}`}>
              <BlockSkeleton withAnimation={true} />
            </div>
            <div className="hz-Block">
              <div className="hz-Listing-title">
                <TextSkeleton />
              </div>
              <div className="hz-Listing-price">
                <TextSkeleton type="short" />
              </div>
            </div>
          </article>
        </div>
      ))}
  </CardCollection>
);

export default FeedItemListSkeleton;
