import React, { useContext, useRef } from 'react';
import { Tabs, Tab } from '@hz-design-system/web-ui';

import { TFeeds } from 'types/TFeeds';
import useSticky from '../../hooks/useSticky';
import EnvironmentContext from '../../../../contexts/EnvironmentContext';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import Classes from './FeedsTabs.scss';

type TFeedsTabsProps = {
  feeds: TFeeds;
  activeFeedIndex: number;
  onClick: Function;
};

const FeedsTabs = ({ feeds, activeFeedIndex, onClick }: TFeedsTabsProps) => {
  const { t } = useI18nContext();
  const { isHeaderSticky } = useContext(EnvironmentContext);
  const stickyStyle = useSticky(isHeaderSticky ? ['header'] : []);

  const ref = useRef<HTMLInputElement>(null);

  const setActiveTab = (index) => {
    const topPosition = ref?.current?.getBoundingClientRect().top ?? 0;
    if (topPosition < stickyStyle.top) {
      window.scrollBy({
        top: topPosition - stickyStyle.top,
        left: 0,
        behavior: 'smooth',
      });
    }

    if (index !== activeFeedIndex) {
      onClick(index);
    }
  };

  return (
    <>
      {/* An empty div helps us to calculate where to we have to scroll up*/}
      <div ref={ref} />
      <nav role="navigation" aria-label={t('homepage.feeds.tabs_nav_aria_label')}>
        <Tabs className={Classes.tabs} style={stickyStyle} activeTab={activeFeedIndex} setActiveTab={setActiveTab}>
          {feeds.map((feed, index) => (
            <Tab key={index} label={feed.displayTitle} />
          ))}
        </Tabs>
      </nav>
    </>
  );
};

export default FeedsTabs;
