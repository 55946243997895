import fetchAPI from '../../../utils/fetchApi';
import { getSessionStorageItem, setSessionStorageItem } from '../../../utils/sStorage';
import formatFeedItemsUrl from './utils/formatFeedItemsUrl';
import generateFeedItemsParams from './utils/generateFeedItemsParams';

import { TFeed, TFeedItem } from 'types/TFeeds';

const HOMEPAGE_FEED = 'HOMEPAGE_FEED';

type TGetFeedItemsProps = {
  feed: TFeed;
  page: number;
  feedPostcode?: string;
};

type TGetCurrentPageProps = {
  feed: TFeed;
  feedPostcode?: string;
};

const minutes = 60;
const seconds = 60;
const milliseconds = 1000;
const ttlInHours = 6;
// convert hours to milliseconds
const cacheTtl = ttlInHours * minutes * seconds * milliseconds;

const formatCacheKey = ({ feedType, postcode }): string =>
  postcode ? `${HOMEPAGE_FEED}_${feedType}_${postcode}` : `${HOMEPAGE_FEED}_${feedType}`;

export default async function getFeedItems({ feed, feedPostcode, page }: TGetFeedItemsProps): Promise<TFeedItem[]> {
  const params = generateFeedItemsParams({ feed, feedPostcode, page });

  const storageKey = formatCacheKey(params);

  // try to return cached items first
  // if the number of the cached page is greater or equal to the incoming then we return data for all cached pages
  const cachedData = getSessionStorageItem(storageKey, cacheTtl);
  if (cachedData?.feedItemsPage >= page) {
    return cachedData.feedItems;
  }

  try {
    const feedItems = await fetchAPI({ url: formatFeedItemsUrl(params), preloadCompatible: true });

    if (feedItems?.length) {
      // cache response only if it contains feed items
      setSessionStorageItem(storageKey, {
        feedItemsPage: page,
        feedItems: [...(cachedData?.feedItems || []), ...feedItems],
      });
    }

    return feedItems;
  } catch (err) {
    return [];
  }
}

export function getCurrentPage({ feed, feedPostcode }: TGetCurrentPageProps) {
  const storageKey = formatCacheKey(generateFeedItemsParams({ feed, feedPostcode }));

  const cachedData = getSessionStorageItem(storageKey, cacheTtl);

  return cachedData?.feedItemsPage ?? 0;
}
