// Runtime webpack config
import '../../other/WebpackConfig';

import '../../../scss/homepage.twh.nlbe.scss';

// i18n
import i18n from '../../i18n/nl-BE';

import homepage from './homepage';

homepage(i18n);
