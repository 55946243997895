import { useEffect, useState } from 'react';

type TUseSticky = {
  position: 'sticky';
  top: number;
};

// domDependencies - a list of dom elements that must be considered
// when calculating the top position of a sticky element
const useSticky = (domDependencies: string[]): TUseSticky => {
  const [positionTop, setPositionTop] = useState(0);

  useEffect(() => {
    if (!window.ResizeObserver) {
      return;
    }

    const resizeObserver = new window.ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }

        let totalTopPosition = 0;

        // calculate top position based on the height of dom dependencies
        entries.forEach((entry) => {
          totalTopPosition += entry.contentRect.height;
        });

        if (positionTop !== totalTopPosition) {
          setPositionTop(totalTopPosition);
        }
      });
    });

    domDependencies.forEach((selector) => {
      const element = document.querySelector(selector);
      if (element) {
        resizeObserver.observe(element);
      }
    });

    return () => {
      resizeObserver.disconnect();
    };
  }, [domDependencies, positionTop]);

  return {
    position: 'sticky',
    top: positionTop,
  };
};

export default useSticky;
