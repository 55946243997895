import React from 'react';

const defaultValues = {
  staticBaseUrl: '',
  xsrfToken: '',
  baseUrl: '',
  links: {},
};

const EnvironmentContext = React.createContext(defaultValues);

export const EnvironmentProvider = EnvironmentContext.Provider;
export const EnvironmentConsumer = EnvironmentContext.Consumer;

export default EnvironmentContext;
