import React, { useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { displayCookies } from './constants/banners';
import BannerHomeTopContainer from './BannerHomeTopContainer';
import BannerHomeRightContainer from './BannerHomeRightContainer';
import { displayBannerInFeed, showBannersOnMobile, showBannersInFeeds, displayQueuedPosition } from './utils/banners';
import EnvironmentContext from '../../../../contexts/EnvironmentContext';
import fetchAPI from '../../../../utils/fetchApi';
import adobeRegistration from './utils/adobeRegistration';

type TBannerContainerProps = {
  position: string;
};

let rsiSegmentsIsCalled = false;

const BannerContainer = ({ position }: TBannerContainerProps) => {
  const isMobileTop = position === 'MOBILE';
  const {
    links: { audienceTargetingUrl },
    xsrfToken,
    tenantContext: { tenant, locale },
    encryptedUserId,
    fingerPrint,
    audienceTargeting: { isEnabled, crmId, partnerId, customerOrganisationId },
    isAdobeDmpPurposeAccepted,
  } = useContext(EnvironmentContext);

  useEffect(() => {
    window.addEventListener('banner-lib-loaded', displayQueuedPosition);

    if (isAdobeDmpPurposeAccepted && isEnabled && !rsiSegmentsIsCalled) {
      // await is not applied for this function to keep the logic same as current homepage
      // it can be a experiment idea, loading banners after having the segmentation data
      adobeRegistration({ crmId, partnerId, customerOrganisationId, encryptedUserId });

      if (Cookies.get(displayCookies.ADOBE_IDENTIFIER)) {
        rsiSegmentsIsCalled = true;

        const callAudienceTargetApi = async () => {
          try {
            const rsiSegments = await fetchAPI({
              url: audienceTargetingUrl,
              method: 'POST',
              xsrfToken,
              payload: {
                device: 'web',
                encryptedUserId,
                fingerPrint,
                intent: 'home',
                locale,
                searchAttributes: {},
                tenant: tenant.toLocaleUpperCase(),
                uuid: Cookies.get(displayCookies.ADOBE_IDENTIFIER),
              },
            });

            window.ecgJsBanners?.addAdobeRsiSegments(rsiSegments?.segments);
          } catch (e) {
            rsiSegmentsIsCalled = false;
          }
        };

        callAudienceTargetApi().then();
      }
    }

    return () => {
      window.removeEventListener('banner-lib-loaded', displayQueuedPosition);
    };
  });

  useEffect(() => {
    if (isMobileTop) {
      showBannersOnMobile();
    } else {
      displayBannerInFeed(position);
    }
  }, [position, isMobileTop]);

  if (isMobileTop) {
    return <BannerHomeTopContainer isFeedItem={false} />;
  }

  if (!showBannersInFeeds()) {
    return null;
  }

  switch (position) {
    case 'FEED_0':
      return <BannerHomeTopContainer isFeedItem={true} />;
    case 'FEED_1':
      return <BannerHomeRightContainer />;
    default:
      return null;
  }
};

export default BannerContainer;
