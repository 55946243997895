import React from 'react';
import { string, shape } from 'prop-types';
import { EnvironmentProvider } from '../../contexts/EnvironmentContext';
import { useGATrackingForSSR } from '../../utils/gaTracking';

import Feeds from './components/Feeds/Feeds';

const HomepageApp = ({ appState, environment }) => {
  useGATrackingForSSR();

  const { feeds, feedPostcode, initialFeed } = appState;

  return (
    <EnvironmentProvider value={environment}>
      <Feeds feeds={feeds} feedPostcode={feedPostcode} initialFeed={initialFeed} />
    </EnvironmentProvider>
  );
};

HomepageApp.propTypes = {
  appState: shape({
    foo: string,
  }),
  environment: shape({
    xsrfToken: string,
  }),
};

export default HomepageApp;
