import React from 'react';

import Classes from './Skeleton.scss';

type TBlockSkeletonProps = {
  className?: string;
  withAnimation?: boolean;
  children?: React.ReactNode;
};

const BlockSkeleton = ({ className, withAnimation = false, children }: TBlockSkeletonProps) => (
  <div className={`${Classes.base} ${className} ${withAnimation ? Classes.withAnimation : ''}`}>{children}</div>
);

export default BlockSkeleton;
