import React, { useEffect, useState } from 'react';
import { showBannersInFeeds } from './utils/banners';

import Classes from './Banners.scss';

type TBannerHomeTopContainerProps = {
  isFeedItem?: boolean;
};

// this component is used by for you feed and top container
// logic is based on viewport size
const BannerHomeTopContainer = ({ isFeedItem }: TBannerHomeTopContainerProps) => {
  const [showBanner, setShowBanner] = useState(true);
  const [bannerBreakOutClass, setBannerBreakOutClass] = useState('');

  useEffect(() => {
    window.addEventListener('banner-breakout', function () {
      setBannerBreakOutClass(Classes.bannerBreakout);
    });
  }, []);

  useEffect(() => {
    if (!isFeedItem) {
      setShowBanner(!showBannersInFeeds());
    }
  }, [showBanner, isFeedItem]);

  return (
    <>
      {showBanner && (
        <div
          id="banner-top-dt-container"
          className={`hz-FeedBannerBlock ${bannerBreakOutClass} ${
            isFeedItem ? Classes.bannerFeedItem : Classes.bannerTopContainer
          }`}
        >
          <div id="banner-top-dt"></div>
        </div>
      )}
    </>
  );
};

export default BannerHomeTopContainer;
