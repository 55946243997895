import React from 'react';
import { Image } from '@hz-design-system/web-ui';
import { TMpCard } from 'types/TMpCard';

import Classes from './MpCard.scss';

type TMpCardProps = {
  mpCard: TMpCard;
  onClick: Function;
  isLazy?: boolean;
};

const MpCard = ({ mpCard, isLazy = true, onClick }: TMpCardProps) => {
  const { imageUrl, id } = mpCard;

  if (!mpCard) return null;

  const clickHandler = () => {
    onClick();
    if (window?.__HEADER_CONFIG__?.navBar?.userLinks?.placeAd?.url) {
      window.location.href = window.__HEADER_CONFIG__.navBar.userLinks.placeAd.url;
    }
  };

  return (
    <div
      className={`${Classes.mpCard} ${Classes.mpCard}--${id.toLowerCase()}`}
      onClick={clickHandler}
      onKeyDown={(e) => e.key === 'Enter' && clickHandler()}
      role="button"
      tabIndex={0}
    >
      <Image
        src={imageUrl}
        alt="Marketing Card. Click to place an ad"
        loading={isLazy ? 'lazy' : 'eager'}
        decoding={isLazy ? 'async' : 'sync'}
      />
    </div>
  );
};

export default React.memo(MpCard);
