import React from 'react';
import Classes from './Banners.scss';

const BannerHomeRightContainer = () => (
  <div id="banner-right-container" className={`hz-FeedBannerBlock ${Classes.bannerFeedItem}`}>
    <div id="banner-right"></div>
  </div>
);

export default BannerHomeRightContainer;
