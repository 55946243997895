import React from 'react';
import BannerContainer from '../Homepage/components/Banners/BannerContainer';
import { EnvironmentProvider } from '../../contexts/EnvironmentContext';

type TMobileBannerAppProps = {
  environment: {} | any;
};

const MobileBannerApp = ({ environment }: TMobileBannerAppProps) => (
  <EnvironmentProvider value={environment}>
    <BannerContainer position="MOBILE" />
  </EnvironmentProvider>
);

export default MobileBannerApp;
