import React from 'react';

import BlockSkeleton from './BlockSkeleton';

import Classes from './Skeleton.scss';

type TTextSkeletonProps = {
  type?: 'short' | 'regular' | 'long';
  withAnimation?: boolean;
};

const TextSkeleton = ({ type = 'regular', withAnimation }: TTextSkeletonProps) => (
  <BlockSkeleton className={`${Classes.text} ${Classes[type]}`} withAnimation={withAnimation}>
    ...
  </BlockSkeleton>
);

export default TextSkeleton;
