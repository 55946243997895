const safeJSON = (item) => {
  try {
    if (item) {
      return JSON.parse(item);
    }

    return item;
  } catch (err) {
    return;
  }
};

export const getSessionStorageItem = (key: string, ttl?: number) => {
  const created = safeJSON(sessionStorage.getItem(`${key}_CREATED`));

  if (ttl && created < Date.now() - ttl) {
    sessionStorage.removeItem(`${key}_CREATED`);
    sessionStorage.removeItem(`${key}_VALUE`);
    return;
  }

  return safeJSON(sessionStorage.getItem(`${key}_VALUE`));
};

export const setSessionStorageItem = (key: string, item) => {
  try {
    const created = sessionStorage.getItem(`${key}_CREATED`);
    if (!created) {
      sessionStorage.setItem(`${key}_CREATED`, JSON.stringify(Date.now()));
    }
    sessionStorage.setItem(`${key}_VALUE`, JSON.stringify(item));
  } catch (err) {
    sessionStorage.removeItem(`${key}_CREATED`);
    sessionStorage.removeItem(`${key}_VALUE`);
  }
};
