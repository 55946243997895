import React, { useEffect, useState } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { CategoryMenuBar } from '@hz-design-system/web-ui';

import { TCategoryItem } from '@hz-design-system/web-ui/src/types/TCategoryItem';

type TCategoryMenuBarAppProps = {
  appState: {
    categoryLinks?: TCategoryItem[];
    topCategories?: TCategoryItem[];
  };
  environment: {} | any;
};

const LAST_SEARCHES_STORAGE_KEY = 'lastSearches';
const CARS_L1_CATEGORY_ID = 91;
const EV_ENTRY_INDEX = 3;
const EV_CATEGORY_ENTRY = {
  id: 910000,
  key: 'ev',
  parentId: 0,
  parentKey: 'ROOT',
  parentName: 'ROOT',
  parentShortName: 'ROOT',
  name: "Elektrische auto's",
  shortName: 'EVs',
  url: '/cp/elektrische-auto-kopen/',
  icon: 'lightning',
};

const CategoryMenuBarApp = ({ appState: { categoryLinks, topCategories }, environment }: TCategoryMenuBarAppProps) => {
  const [resolvedTopCategories, setResolvedTopCategories] = useState(topCategories);
  const resolvedCategoryLinks = categoryLinks;
  const { t } = useI18nContext();
  const { tenantContext } = environment;

  useEffect(() => {
    if (tenantContext.tenant !== 'mp') {
      return;
    }

    try {
      const localStorageValue = localStorage.getItem(LAST_SEARCHES_STORAGE_KEY);
      if (localStorageValue) {
        const parsedValue = JSON.parse(localStorageValue);
        const carsLastSearch = parsedValue.filter((entry) => entry.l1CategoryId === CARS_L1_CATEGORY_ID);

        if (carsLastSearch.length && resolvedTopCategories && resolvedCategoryLinks) {
          const resolvedTopCategoriesWithEv = [...resolvedTopCategories];
          resolvedCategoryLinks.splice(EV_ENTRY_INDEX, 0, EV_CATEGORY_ENTRY);
          resolvedTopCategoriesWithEv.splice(1, 0, EV_CATEGORY_ENTRY);
          resolvedTopCategoriesWithEv.pop();
          setResolvedTopCategories(resolvedTopCategoriesWithEv);
        }
      }
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!resolvedCategoryLinks || !resolvedTopCategories) {
    return null;
  }

  return (
    <CategoryMenuBar
      l1Categories={resolvedCategoryLinks}
      topCategories={resolvedTopCategories}
      toggleLabel={t('homepage.navigation.categories.all_categories')}
    />
  );
};

export default CategoryMenuBarApp;
