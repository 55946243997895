import React, { useContext } from 'react';
import { Title3, Button } from '@hz-design-system/web-ui';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import EnvironmentContext from '../../../../contexts/EnvironmentContext';

import Classes from './PostcodePrompt.scss';

const PostcodePrompt = () => {
  const { t } = useI18nContext();
  const {
    links: { myAccount },
  } = useContext(EnvironmentContext);

  return (
    <div className={Classes.root}>
      <Title3>{t('homepage.feeds.postcode_prompt.title')}</Title3>
      <Trans className={Classes.message} i18nKey="homepage.feeds.postcode_prompt.message" />
      <Button.Primary as="a" href={myAccount}>
        {t('homepage.feeds.postcode_prompt.cta')}
      </Button.Primary>
    </div>
  );
};

export default PostcodePrompt;
