import { TMpCard } from 'types/TMpCard';
import fetchAPI from '../../../utils/fetchApi';

export default async function getMpCards(): Promise<TMpCard[]> {
  try {
    return await fetchAPI({
      url: '/hp/api/mp-cards',
    });
  } catch {
    return [];
  }
}
