import { currency } from '@ecg-marktplaats/aurora-js-formatters';
import { TPrice } from 'types/TFeeds';

/**
 * Formats price name or monetary value
 * Ported from @ecg-marktplaats/aurora-templates/src/helpers/formatPrice.js
 */
export default (price: TPrice, t: Function): string => {
  const { priceCents, priceType } = price;
  const hasMonetaryValue = ['FIXED', 'FREE_BID', 'MIN_BID'].includes(priceType);

  return hasMonetaryValue ? currency(priceCents, true) : t(`priceTypes:prices.${priceType}`);
};
