import { displayCookies } from '../constants/banners';

const getConfig = (partnerId, customerOrganisationId) => ({
  partner: partnerId,
  containerNSID: 0,
  uuidCookie: {
    name: displayCookies.ADOBE_IDENTIFIER,
    days: 30,
  },
  visitorService: {
    namespace: customerOrganisationId,
  },
});

type TAdobeRegistrationProps = {
  encryptedUserId: string;
  customerOrganisationId: string;
  partnerId: string;
  crmId: string;
};

export default ({ crmId, partnerId, customerOrganisationId, encryptedUserId }: TAdobeRegistrationProps) => {
  Promise.all([
    import(/* webpackChunkName: "adobeVisitorApi" */ '@ecg-marktplaats/adobe-dmp-js/src/adobeVisitorApi'),
    import(/* webpackChunkName: "adobeDIL" */ '@ecg-marktplaats/adobe-dmp-js/src/adobeDIL'),
  ]).then(([{ default: AdobeVisitorApi }, { default: AdobeDIL }]) => {
    AdobeDIL.create(getConfig(partnerId, customerOrganisationId));
    if (encryptedUserId && crmId) {
      const visitorApiInstance = AdobeVisitorApi.getInstance(customerOrganisationId);

      visitorApiInstance.setCustomerIDs({
        [crmId]: {
          id: encryptedUserId,
          authState: AdobeVisitorApi?.AuthState?.AUTHENTICATED || null,
        },
      });
    }
  });
};
