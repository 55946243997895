import { useEffect } from 'react';

const ATTRIBUTES = {
  TRACK_EVENT: 'data-ga-track-event',
};

const gAEventOnce = new Set();

/**
 * Tracks event in GA
 */
const trackGAEvent = (eventAction: string, eventLabel: string = '') => {
  if (eventAction) {
    window.ecGa('trackEvent', { eventAction, eventLabel });
  }
};

const trackGAEventOnce = (eventAction: string, eventLabel: string = '') => {
  if (!gAEventOnce.has(eventAction + eventLabel)) {
    gAEventOnce.add(eventAction + eventLabel);
    trackGAEvent(eventAction, eventLabel);
  }
};

const handleClickEvent = (event) => {
  const { gaTrackEvent } = (event.currentTarget as HTMLElement)?.dataset;

  if (gaTrackEvent) {
    const [eventAction, eventLabel] = gaTrackEvent.split(',').map((value) => decodeURIComponent(value));

    trackGAEvent(eventAction, eventLabel);
  }
};

/**
 * Attaches `onClick` event handler to track click on *SSR-only* elements in GA
 * Used for links in app-shell that are not rehydrated client-side.
 */
const useGATrackingForSSR = function () {
  useEffect(() => {
    document.querySelectorAll(`[${[ATTRIBUTES.TRACK_EVENT]}]`).forEach((item) => {
      item.addEventListener('click', handleClickEvent, false);
    });
  }, []); // `[]` to run effect only ones
};

/**
 * Returns attribute to enable tracking the click in GA
 * Apply for links in app-shell that are not rehydrated client-side
 */
const getGATrackingAttrForSSR = (label: string, action: string): object => ({
  [ATTRIBUTES.TRACK_EVENT]: `${encodeURIComponent(label)},${encodeURIComponent(action)}`,
});

export { getGATrackingAttrForSSR, trackGAEvent, trackGAEventOnce, useGATrackingForSSR };
