import { TFeed } from 'types/TFeeds';

type TParams = {
  feed: TFeed;
  feedPostcode?: string;
  page?: number;
};

/**
 * Generate query params to fetch feed items
 */
export default ({ feed, feedPostcode, page = 0 }: TParams) => ({
  feedType: feed.feedType,
  // eslint-disable-next-line no-undefined
  postcode: feed.locationRequired ? feedPostcode : undefined,
  page,
});
