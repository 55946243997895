import React, { useMemo } from 'react';
import { Image } from '@hz-design-system/web-ui';
import { TMpCard } from 'types/TMpCard';
import Classes from './MpCard.scss';

const MpCardBanner = ({ mpCards, onClick }: { mpCards: TMpCard[]; onClick: Function }) => {
  const mpCardBanner = useMemo(() => {
    if (!mpCards?.length) return null;
    return mpCards.find((card) => card.id === 'mp-cards-homepage-top-banner') || null;
  }, [mpCards]);

  if (!mpCardBanner) return null;

  const clickHandler = () => {
    onClick();
    if (window?.__HEADER_CONFIG__?.navBar?.userLinks?.placeAd?.url) {
      window.location.href = window.__HEADER_CONFIG__.navBar.userLinks.placeAd.url;
    }
  };

  return (
    <div
      className={Classes.mpCardBanner}
      onClick={clickHandler}
      onKeyDown={(e) => e.key === 'Enter' && clickHandler()}
      role="button"
      tabIndex={0}
    >
      <Image src={mpCardBanner.imageUrl} alt="Marketing Banner. Click to place an ad" loading="eager" decoding="sync" />
    </div>
  );
};

export default React.memo(MpCardBanner);
